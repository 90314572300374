import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { CommonFunction } from '../common-function';
import { Router } from '@angular/router';
// import { AuthenticationService } from '../services/authentication.service';
// import { LoginDataOut } from '../model/authModel/Authentication';
// import { SessionTimeoutService } from '../services/session-timeout.service';
// import * as jwt_decode from 'jwt-decode';
// import { AppConfigService } from '../config/app.config.service';
import { AuthService } from '../services/auth.service';
import { LoginDataOut } from '../models/authentication';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  private commonfunction: CommonFunction;
  // private config: AppConfigService;
  // private authService: AuthenticationService;
  // private sessionTimeoutService: SessionTimeoutService;
  private router: Router;
  authService: AuthService;

  constructor(
    // private commonfunction: CommonFunction,
    // private config: AppConfig,
    // private authService: AuthService,
    // private sessionTimeoutService: SessionTimeoutService,
    // private router: Router,
    private injector: Injector
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        // se l'errore proviene dal refresh significa che il token è scaduto-> si torna al login

        // if (request.url === this.authService.full_path + 'refreshToken') {

        let fullpath: string;
        // fullpath =  this.config.getConfig('api_root') +
        //   this.config.getConfig('authentication_path') +
        //   'auth/';
        this.authService = this.injector.get(AuthService);
        fullpath = this.authService.full_path;
        if (request.url === fullpath + 'refreshToken') {
          console.log('Interceptor - Error during refresh_Token - Interceptor');
          this.isRefreshing = false;
          sessionStorage.setItem('logged', '0');
          this.router = this.injector.get(Router);
          this.router.navigate(['/sign-in']);
          return throwError(error);
        }

        if (error instanceof HttpErrorResponse && error.status === 401) {
          console.log(
            'Interceptor - 401 during httpCall - Interceptor: ',
            request.url
          );
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    if (request.url.includes('/users/login')) {
      return request;
    } else {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }

  // gestione dell'errore di autorizzazione
  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.headers.get('Authorization')) {
      const jwt = sessionStorage.getItem('token');
      return next.handle(this.addToken(request, jwt));
    }

    if (!this.isRefreshing) {
      console.log('Interceptor - Refresh Token - Interceptor: ', request.url);
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      // chiamo il servizio di refresh per provare ad aggiornare il token
      return this.authService.refreshToken().pipe(
        switchMap((result: any) => {
          this.commonfunction = this.injector.get(CommonFunction);
          const refreshResponse: LoginDataOut = this.commonfunction.extractBodyResponse(
            result
          );
          this.isRefreshing = false;
          sessionStorage.setItem('token', 'Bearer ' + refreshResponse.jwtToken);
          sessionStorage.setItem(
            'refreshToken',
            'Bearer ' + refreshResponse.refreshToken
          );

          // Reset timeout Session Check
          // const token = jwt_decode(refreshResponse.jwtToken);
          // const refreshToken = jwt_decode(refreshResponse.refreshToken);
          //        const timeout = new Date((+token['exp'] - 90) * 1000).toLocaleString(
          //          'en-ZA'
          //        );

          // const timeout = new Date(
          //   (+refreshToken['exp'] - 90) * 1000
          // ).toLocaleString('en-ZA');
          // sessionStorage.setItem('sessionTimeout', timeout);
          // this.sessionTimeoutService = this.injector.get(SessionTimeoutService);
          // this.sessionTimeoutService.changeTimerStatus(false);
          // this.sessionTimeoutService.setEndTimerDate(timeout);
          // this.sessionTimeoutService.changeTimerStatus(true);

          this.refreshTokenSubject.next(refreshResponse.jwtToken);
          return next.handle(this.addToken(request, refreshResponse.jwtToken));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}
