import { throwError as observableThrowError, Observable, of } from 'rxjs';

import { catchError, tap, switchMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';

export interface EnvConfig {
  env: string;
}

export class ConfigData {
  api_root: string;
  root_path: string;
  image_path: string;
  context_root: string;
  slots_path: string;
  customers_path: string;
  operators_path: string;
  customer_services_path: string;
  authentication_path: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private config: ConfigData = new ConfigData();
  private env: Object = null;

  constructor(private http: HttpClient) {}

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    let property: string = this.config[key];
    // if (isNullOrUndefined(property)) {
    //   this.loadMiss(key).subscribe((res) => {
    //     console.log('MISS retrieved Data: ', res);
    //     property = res;
    //     return res;
    //   });
    //   console.log('MISS: ', key);
    // }
    // return this.config[key];
    return property;
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */
  public async load() {
    const responseData = await this.http
      .get('./assets/env/env.json')
      .pipe(
        switchMap((res: any) =>
          this.http.get<ConfigData>('./assets/env/env.' + res.env + '.json')
        )
      )
      .toPromise()
      .then((res) => {
        // console.log('subscribe: ', res);
        this.config = res;
      });
  }

  public loadMiss(property: string): Observable<any> {
    return this.http.get<EnvConfig>('./assets/env/env.json').pipe(
      tap((res) => console.log('tap: ', res, res.env)),
      switchMap((res) =>
        this.http.get<ConfigData>('./assets/env/env.' + res.env + '.json')
      ),
      switchMap((cfg) => of(cfg[property]))
    );
  }

  public loadOld() {
    return new Promise((resolve, reject) => {
      this.http
        .get('./assets/env/env.json')
        .pipe(
          tap((res) => console.log(res)),
          switchMap((res: any) => [res]),
          map((res) => res.env)
        )
        .pipe(
          catchError((error: any): any => {
            console.log('Configuration file "env.json" could not be read');
            resolve(true);
            return observableThrowError(error.json().error || 'Server error');
          })
        )

        .subscribe((envResponse) => {
          this.env = envResponse;
          let request: any = null;

          switch (envResponse.env) {
            case 'production':
              {
                request = this.http.get(
                  './assets/env/env.' + envResponse.env + '.json'
                );
              }
              break;

            case 'development':
              {
                request = this.http.get(
                  './assets/env/env.' + envResponse.env + '.json'
                );
              }
              break;

            case 'default':
              {
                console.error('Environment file is not set or invalid');
                resolve(true);
              }
              break;
          }

          if (request) {
            request
              .map((res) => res.json())
              .catch((error: any) => {
                console.error(
                  'Error reading ' + envResponse.env + ' configuration file'
                );
                resolve(error);
                return observableThrowError(
                  error.json().error || 'Server error'
                );
              })
              .subscribe((responseData: any) => {
                this.config = responseData;
                // console.log(this.config);
                resolve(true);
              });
          } else {
            console.error('Env config file "env.json" is not valid');
            resolve(true);
          }
        });
    });
  }
}
