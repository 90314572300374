<div>
  <app-header [sidenav]="leftSidenav"></app-header>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #leftSidenav mode="side" opened="false">
      <app-left-menu></app-left-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <!-- <mat-sidenav-content [@onMainContentChange]="onSideNavChange ? 'open': 'close'"> -->

      <div class="main_content">
        <router-outlet></router-outlet>
      </div>

    </mat-sidenav-content>
  </mat-sidenav-container>

</div>
