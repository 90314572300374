import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { twoDigits } from 'mat-timepicker/lib/util';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { sha512 } from 'js-sha512';
import { AppConfigService } from 'src/app/config/app.config.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  pass_regex: RegExp = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*)(+=._-]{6,20}$'
  );

  public signupFormGroup: FormGroup;

  showConfirmMessage = false;
  confirmMessage =
    'Abbiamo inviato una conferma di registrazione alla tua mail. Clicca sul link ricevuto per confermare.';
  api_root: string;
  module_path: string;
  resource_path: string;
  full_path: any;

  constructor(
    private formBuilder: FormBuilder,
    private config: AppConfigService,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    //  this.formGroup = this.formBuilder.group(
    //    {
    //      password: ['', [Validators.required, Validators.minLength(8)]],
    //      password2: ['', [Validators.required]],
    //    },
    //    { validator: passwordMatchValidator }
    //  );

    this.signupFormGroup = this.formBuilder.group({
      nameFormControl: ['', [Validators.required, Validators.minLength(3)]],
      surnameFormControl: ['', [Validators.required, Validators.minLength(3)]],
      emailFormControl: ['', [Validators.required, Validators.email]],
      passwordFormControl: [
        '',
        [Validators.required, Validators.pattern(this.pass_regex)],
      ],
      passwordConfirmFormControl: [
        '',
        [Validators.required, Validators.pattern(this.pass_regex)],
      ],
      termsAcceptanceFormControl: ['', [Validators.required]],
    });

    this.api_root = this.config.getConfig('api_root');
    this.module_path = this.config.getConfig('authentication_path');
    this.resource_path = 'auth/';
    this.full_path = this.api_root + this.module_path;
    console.log('SignUpInit(): ', this.full_path);
  }

  // nameFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.minLength(3),
  // ]);

  // surnameFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.minLength(3),
  // ]);

  // emailFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.email,
  // ]);

  // passwordFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.pattern(this.pass_regex),
  // ]);

  // passwordConfirmFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.pattern(this.pass_regex),
  // ]);

  // termsAcceptanceFormControl = new FormControl('', [Validators.required]);

  // get formValid() {
  //   const valid =
  //     this.passwordConfirmFormControl.valid &&
  //     this.passwordFormControl.valid &&
  //     this.emailFormControl.valid &&
  //     this.surnameFormControl.valid &&
  //     this.termsAcceptanceFormControl.valid &&
  //     this.nameFormControl.valid;

  //   return valid;
  // }

  onPasswordConfirmInput() {
    // console.log('start');
    if (!this.signupFormGroup.get('passwordFormControl').hasError('required')) {
      if (
        this.signupFormGroup.get('passwordConfirmFormControl').value !==
        this.signupFormGroup.get('passwordFormControl').value
      ) {
        // console.log('setErrors');
        this.signupFormGroup.get('passwordConfirmFormControl').setErrors({
          confirmedDoesNotMatch: true,
        });
      } else {
        // console.log('else');
        this.signupFormGroup.get('passwordConfirmFormControl').setErrors(null);
      }
    }
  }

  signup() {
    // console.log(this.signupFormGroup.get('emailFormControl').value);
    // console.log(this.signupFormGroup.get('passwordFormControl').value);
    const hashedPassword = sha512
      .create()
      .update(this.signupFormGroup.get('passwordFormControl').value)
      .hex();
    this.authService
      .register(
        this.signupFormGroup.get('emailFormControl').value,
        this.signupFormGroup.get('nameFormControl').value,
        this.signupFormGroup.get('surnameFormControl').value,
        hashedPassword,
        this.full_path
      )
      .pipe(
        catchError((error) => {
          console.log('caught mapping error and rethrowing', error);
          return throwError(error);
        })
        // finalize(() => console.log('first finalize() block executed'))
      )
      .subscribe(
        (response) => {
          this.showConfirmMessage = true;
        },
        (error) => {
          if (error.status === 409) {
            // this.isErrLogin = true;
            // this.errortype = 'errUserLogged';
            this.notificationService.showError(
              'Email esistente o utente già registrato!'
            );
            return;
          } else {
            this.notificationService.showError(
              "Ops, c'è stato un problema! Riprova"
            );
          }
          console.log('ERRORE', error);
          console.log('STATUS OF ERROR: ', error.status);
          sessionStorage.setItem('logged', '0');
        }
      );
  }
}

export const passwordMatchValidator: ValidatorFn = (
  formGroup: FormGroup
): ValidationErrors | null => {
  if (formGroup.get('password').value === formGroup.get('password2').value)
    return null;
  else return { passwordMismatch: true };
};
