import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CommonFunction } from '../common-function';
import { AppConfigService } from '../config/app.config.service';
import {
  AuthModelType,
  AuthorizeData,
  AuthorizeDataOut,
  ChangePassword,
  ConfirmationResponse,
  LoginData,
  LoginDataOut,
  RegisterData,
  ReplacePassword,
} from '../models/authentication';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private api_root: any;
  private module_path: string;
  private resource_path: string;
  public full_path: string;
  result: Object;
  authControlTypeRes = new Subject<AuthModelType>();
  configService: AppConfigService;

  constructor(
    private _http: HttpClient,
    // private config: AppConfigService,
    private injector: Injector,
    private commonFunction: CommonFunction
  ) {
    this.configService = this.injector.get(AppConfigService);
    this.api_root = this.configService.getConfig('api_root');
    this.module_path = this.configService.getConfig('authentication_path');
    this.resource_path = 'auth/';
    this.full_path = this.api_root + this.module_path;
    console.log('auth: ', this.api_root);

    //this.full_path = 'http://localhost:8082/app/v1/' + 'users/';
    // + this.resource_path;
    // this.full_path = 'http://10.166.16.120:8080/cops_ca_authentication/auth/';
    // this.full_path = 'http://localhost:8080/cops_ca_authentication/auth/';
    // console.log(this.module_path);
  }

  // Login User
  login(
    username: string,
    password: string,
    url: string,
    authControlType?: string
  ): Observable<any> {
    const httpOptions = this.commonFunction.getHttpOptions();

    httpOptions.headers = this.commonFunction.setHeader(0, true);

    const body = new LoginData();
    body.username = username;
    body.password = password.toUpperCase();
    body.authControlType = authControlType;
    const data = JSON.stringify(body);

    let loginUrl =
      this.full_path == null || this.full_path == null ? url : this.full_path;

    return this._http
      .post<LoginDataOut>(loginUrl + 'login', data, httpOptions)
      .pipe(
        map((result) => (this.result = result)),
        catchError(this.commonFunction.errorHandler)
      );
  }

  register(
    username: string,
    name: string,
    surname: string,
    password: string,
    url: string
  ): Observable<any> {
    const httpOptions = this.commonFunction.getHttpOptions();

    httpOptions.headers = this.commonFunction.setHeader(0, true);

    const body = new RegisterData();
    body.email = username;
    body.firstName = name;
    body.lastName = surname;
    body.password = password.toUpperCase();
    const data = JSON.stringify(body);

    let singupUrl =
      this.full_path == null || this.full_path == null ? url : this.full_path;

    return this._http
      .post<LoginDataOut>(singupUrl + 'registration', data, httpOptions)
      .pipe(
        map((result) => (this.result = result)),
        catchError(this.commonFunction.errorHandler)
      );
  }

  // Logout User
  logout(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: sessionStorage.getItem('token'),
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        'X-Content-Type-Options': 'nosniff',
      }),

      observe: 'response' as 'body',
    };

    // console.log('TokenJJWT: ', sessionStorage.getItem('token'));

    return this._http.post(this.full_path + 'logout', null, httpOptions).pipe(
      map((result) => result),
      catchError(this.commonFunction.errorHandler)
    );
  }

  // Change User Password
  changePassword(
    username: string,
    oldPass: string,
    newPass: string
  ): Observable<any> {
    const httpOptions = this.commonFunction.getHttpOptions();
    httpOptions.headers = this.commonFunction.setHeader(1);

    const body = new ChangePassword();
    body.username = username;
    body.oldPassword = oldPass;
    body.newPassword = newPass;
    const data = JSON.stringify(body);

    return this._http
      .post(this.full_path + 'passwordChange', data, httpOptions)
      .pipe(
        map((result) => (this.result = result)),
        catchError(this.commonFunction.errorHandler)
      );
  }



  // Change User Password
  resetPassword(
    email: string,
    newPass: string,
  ): Observable<any> {
    const httpOptions = this.commonFunction.getHttpOptions();
    httpOptions.headers = this.commonFunction.setHeader(1);

    const body = new RegisterData();
    body.email = email;
    body.password = newPass;
    const data = JSON.stringify(body);

    return this._http
      .post(this.full_path + 'reset-pwd', data, httpOptions)
      .pipe(
        map((result) => (this.result = result)),
        catchError(this.commonFunction.errorHandler)
      );
  }

  // Authorize User function code
  authorizeUserFunction(
    authControlType: string,
    functions: string[]
  ): Observable<any> {
    const httpOptions = this.commonFunction.getHttpOptions();
    httpOptions.headers = this.commonFunction.setHeader(1);

    const body = new AuthorizeData();
    body.authControlType = authControlType;
    body.functions = functions;
    const data = JSON.stringify(body);

    return this._http
      .post<AuthorizeDataOut>(this.full_path + 'authorize', data, httpOptions)
      .pipe(
        map((result) => (this.result = result)),
        catchError(this.commonFunction.errorHandler)
      );
  }

  // Refressh JWT token
  refreshToken(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: sessionStorage.getItem('refreshToken'),
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        'X-Content-Type-Options': 'nosniff',
      }),

      observe: 'response' as 'body',
    };

    const data = null;

    return this._http
      .post<LoginDataOut>(this.full_path + 'refreshToken', data, httpOptions)
      .pipe(
        map((result) => (this.result = result)),
        catchError(this.commonFunction.errorHandler)
      );
  }

  getConfirmnationResponse(token: string) {
    const httpOptions = this.commonFunction.getHttpOptions();
    httpOptions.headers = this.commonFunction.setHeader(0, true);
    let params = new HttpParams();
    params = token ? params.set('token', token) : params;
    httpOptions.params = params;

    return this._http
      .get<ConfirmationResponse>(
        this.full_path + 'registrationConfirm',
        httpOptions
      )
      .pipe(
        map((result) => (this.result = result)),
        catchError(this.commonFunction.errorHandler)
      );
  }

  getPwdResetConfirmationResponse(token: string) {
    const httpOptions = this.commonFunction.getHttpOptions();
    httpOptions.headers = this.commonFunction.setHeader(0, true);
    let params = new HttpParams();
    params = token ? params.set('token', token) : params;
    httpOptions.params = params;

    return this._http
      .get<ConfirmationResponse>(
        this.full_path + 'pwdResetConfirm',
        httpOptions
      )
      .pipe(
        map((result) => (this.result = result)),
        catchError(this.commonFunction.errorHandler)
      );
  }

  replaceUserPass(username: string, newPassword: string) {
    const httpOptions = this.commonFunction.getHttpOptions();

    httpOptions.headers = this.commonFunction.setHeader(0);

    const body = new ReplacePassword();
    body.username = username;
    body.newPassword = newPassword;
    const data = JSON.stringify(body);

    return this._http
      .post(this.full_path + 'replacePassword', data, httpOptions)
      .pipe(
        map((result) => (this.result = result)),
        catchError(this.commonFunction.errorHandler)
      );
  }
}
