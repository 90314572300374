import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  AfterViewInit,
  AfterViewChecked,
} from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SidenavService } from '../services/sidenav.service';
import { onMainContentChange } from '../animations/animations';
import { MatCalendar } from '@angular/material/datepicker/calendar';
import { Moment } from 'moment';
import { CommonFunction } from '../common-function';
import { ReservationStepperService } from '../services/reservation-stepper.service';
import { CustomerServices } from '../models/customer-services';
import { FreeSlot } from '../models/slots';
import { MatStepper } from '@angular/material/stepper';
import { SlotService } from '../services/slot.service';
import { Operators } from '../models/operators';
import { IcsFileManager } from '../shared/ics-file-manager';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
  animations: [onMainContentChange],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class FullLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('leftSidenav') sidenav: MatSidenav;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  public onSideNavChange: boolean;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private sidenavService: SidenavService,
    private _snackBar: MatSnackBar
  ) {
    this.sidenavService.sideNavState$.subscribe((res) => {
      console.log(res);
      this.onSideNavChange = res;
    });
  }

  ngAfterViewInit(): void {
    // this.sidenav.toggle();
    // this.sidenavService.closeSideNav(false);
  }

  ngOnInit() {
    // this.sidenav.toggle();
    // this.sidenavService.closeSideNav(false);
  }

  ngOnDestroy(): void {}

  openSnackBar(message?: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['success-alert'],
    });
  }
}
