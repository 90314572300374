import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullLayoutComponent } from './full-layout/full-layout.component';
// import { LayoutModule } from '@angular/cdk/layout';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { Approutes } from './app-routing.module';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { SidenavService } from './services/sidenav.service';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AppConfigService } from './config/app.config.service';
import { APP_INITIALIZER } from '@angular/core';
import { DemoMaterialModule } from './material.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

// import { MaterialModule } from './material.module';
import { DatePipe } from '@angular/common';
// import { CalendarMainComponent } from './components/calendar-main/calendar-main.component';
// import { TimeSelectionComponent } from './components/time-selection/time-selection.component';
// import { ServiceSelctionComponent } from './components/service-selction/service-selction.component';
// import { MaterialElevationDirective } from './shared/material-elevation.directive';
// import { OperatorSelectionComponent } from './components/operator-selection/operator-selection.component';
// import { ReservationListComponent } from './components/reservation-list/reservation-list.component';
// import { ReservationMakerComponent } from './components/reservation-maker/reservation-maker.component';
import { HomeComponent } from './components/home/home.component';
import { ModalConfirmComponent } from './shared/modal-confirm/modal-confirm.component';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
// import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
// import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
// import { TokenInterceptor } from './interceptor/token-interceptor';
import { AuthGuard } from './auth/auth.guard';
// import { AuthConfigModule } from './auth/auth-config/auth-config.module';
import { AuthInterceptor } from './interceptor/authInterceptor';
import { ComponentGuard } from './auth/component.guard';
import { MailVerificationComponent } from './shared/mail-verification/mail-verification.component';
import { PwdResetComponent } from './shared/pwd-reset/pwd-reset.component';
import { GlobalErrorHandler } from './shared/GlobalErrorHandler';
import { AuthService } from './services/auth.service';

export function initConfig(config: AppConfigService) {
  return () => config.load();
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    HeaderComponent,
    LeftMenuComponent,
    HomeComponent,
    ModalConfirmComponent,
    SignInComponent,
    SignUpComponent,
    ResetPasswordComponent,
    MailVerificationComponent,
    PwdResetComponent,
  ],
  imports: [
    // AuthConfigModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    DemoMaterialModule,
    // MatNativeDateModule,
    ReactiveFormsModule,
    AppRoutingModule,
    // LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    RouterModule.forRoot(Approutes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
    // OAuthModule.forRoot({
    //   resourceServer: {
    //     allowedUrls: ['http://localhost:8082/app/v1/'],
    //     sendAccessToken: true,
    //   },
    // }),
    MatDatepickerModule,
    MatMomentDateModule,
    MatDialogModule,
    // LoadingSpinnerComponent,
  ],
  providers: [
    // AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfigService],
      multi: true,
    },
    SidenavService,
    // AuthService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    DatePipe,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    AuthGuard,
    ComponentGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      //   deps: [AppConfig, CommonFunction, SessionTimeoutService, Router],
      //   deps: [Router],
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptor,
    //   multi: true,
    // },
    // { provide: OAuthStorage, useValue: localStorage },
  ],
  bootstrap: [AppComponent],
  // exports: [LoadingSpinnerComponent],
})
export class AppModule {
  env: string;
  context_root: string;
}
