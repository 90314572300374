<div class="homepage">
  <div class="home-content">

    <div class="greetings">
      <h1 class="d-flex justify-content-center">Bentornato</h1>
      <h1 class="d-flex justify-content-center">{{firstName}}</h1>
    </div>

    <div class="container-fluid">
      <div class="row  mx-auto action-menu">


        <div class="col-sm" style="display: flex;
  justify-content: center; padding: 20px;">
          <div>
            <div style="display: flex;
                      justify-content: center;">
              <a mat-fab routerLink="/app/price-list" style="text-align: center;">
                <mat-icon>receipt</mat-icon>
              </a>
            </div>
            <div style="margin-top: 10px;"><strong>Listino Prezzi</strong>
            </div>
          </div>
        </div>

        <div class="col-sm" style="display: flex;
  justify-content: center; padding: 20px;">
          <div>
            <div style="display: flex;
  justify-content: center;">
              <a mat-fab routerLink="/app/reservation-list" style="text-align: center;">
                <mat-icon>playlist_add_check</mat-icon>
              </a>
            </div>
            <div style="margin-top: 10px;"><strong>Prenotazione Attive</strong>
            </div>
          </div>
        </div>

        <div class="col-sm" style="display: flex;
  justify-content: center; padding: 20px;">
          <div>
            <div style="display: flex;
  justify-content: center;">
              <a mat-fab routerLink="/app/reservation-add" style="text-align: center;">
                <mat-icon>book_online</mat-icon>
              </a>
            </div>
            <div style="margin-top: 10px;"><strong>Nuova Prenotazione</strong>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>
