import { Component } from '@angular/core';
import {
  AuthConfig,
  JwksValidationHandler,
  NullValidationHandler,
  OAuthService,
} from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { authCodeFlowConfig } from './auth.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'reservations-ui';

  constructor() {
    // constructor(private oauthService: OAuthService) {
    // this.configure();
    // this.oauthService.configure(authCodeFlowConfig);
    // this.oauthService.loadDiscoveryDocumentAndLogin();
    // this.oauthService.setupAutomaticSilentRefresh();
    // this.oauthService.initLoginFlow();
    // this.oauthService.initCodeFlow();
    //this.oauthService.setupAutomaticSilentRefresh();
    // Automatically load user profile
    // this.oauthService.events
    //   .pipe(filter((e) => e.type === 'token_received'))
    //   .subscribe((_) => this.oauthService.loadUserProfile());
  }

  // get userName(): string {
  //   const claims = this.oauthService.getIdentityClaims();
  //   if (!claims) return null;
  //   return claims['given_name'];
  // }

  // get idToken(): string {
  //   return this.oauthService.getIdToken();
  // }

  // get accessToken(): string {
  //   return this.oauthService.getAccessToken();
  // }

  // refresh() {
  //   this.oauthService.refreshToken();
  // }

  // authConfig: AuthConfig = {
  //   issuer: 'http://173.249.34.38:8080/auth/realms/barber-reservation',
  //   redirectUri: window.location.origin + '/pippo',
  //   clientId: 'barber-frontend',
  //   scope: 'openid profile email offline_access roles web-origins',
  //   responseType: 'code',
  //   // at_hash is not present in JWT token
  //   disableAtHashCheck: true,
  //   showDebugInformation: true,
  //   requireHttps: false,
  // };

  // private configure() {
  //   this.oauthService.configure(this.authConfig);
  //   this.oauthService.tokenValidationHandler = new NullValidationHandler();
  //   this.oauthService.loadDiscoveryDocumentAndTryLogin();
  // }
}
