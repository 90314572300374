import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from 'src/app/common-function';
import { ConfirmationResponse } from 'src/app/models/authentication';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-mail-verification',
  templateUrl: './mail-verification.component.html',
  styleUrls: ['./mail-verification.component.scss'],
})
export class MailVerificationComponent implements OnInit {
  mailVerified = undefined;
  firstName: string;
  confirmationToken: string = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private commonFunction: CommonFunction
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.confirmationToken = params['token'];
      if (this.confirmationToken) {
        console.log(this.confirmationToken);
        this.getConfirmation(this.confirmationToken);
      }
    });
  }

  goToLogin() {
    this.router.navigate(['/sign-in']);
  }

  getConfirmation(token: string) {
    this.authService.getConfirmnationResponse(token).subscribe(
      (response) => {
        const confirmationResponse: ConfirmationResponse = this.commonFunction.extractBodyResponse(
          response
        );
        if (confirmationResponse) {
          if (confirmationResponse.tokenStatus === 'valid') {
            this.mailVerified = true;
            this.firstName = confirmationResponse.firstName;
          }
        }
      },
      (error) => {
        console.log('ERRORE', error);
        console.log('STATUS OF ERROR: ', error.status);
      }
    );
  }
}
