export class Authentication {}

// Input DataModel

export class LoginData {
  username: string;
  password: string;
  authControlType: string;
}

export class RegisterData {
  firstName: string;
  lastName: string;
  username?: string;
  email: string;
  password: string;
  matchingPassword?: string;
}

export class LoginDataOut {
  entity: string;
  jwtToken: string;
  refreshToken: string;
  passwStatus?: string;
  userId?: string;
  firstName?: string;
}

export class ChangePassword {
  username: string;
  oldPassword: string;
  newPassword: string;
}

export class AuthorizeData {
  authControlType: string;
  functions: string[];
}

export class AuthorizeDataOut {
  authorized: boolean;
}

export class MultiAuthData {
  entityId: string;
  functionCode: string;
}

export class MultiAuthDataOut {
  authControlType: string;
}

export class AuthModelType {
  error: string;
  authControlType: string;
}

export class ReplacePassword {
  username: string;
  newPassword: string;
}

export class ConfirmationResponse {
  firstName: string;
  tokenStatus: string;
}
