<div class="main-wrapper flex">

  <mat-card class="box flex mx-auto">
    <mat-card-header>
      <mat-card-title>Login</mat-card-title>
    </mat-card-header>

    <!-- <form class="example-form" #loginForm=ngForm>
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Username" name="username" [(ngModel)]="username">
          <mat-error>
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Password" name="password" [(ngModel)]="password">
        </mat-form-field>
      </mat-card-content>
      <button mat-stroked-button color="primary" class="btn-block" (click)="login()">Login</button>
    </form> -->

    <form class="example-form" (ngSubmit)="login()">

      <mat-form-field class="example-full-width">
        <mat-label>Email</mat-label>
        <input type="email" matInput [formControl]="emailFormControl" placeholder="Es. nome@example.com" maxlength="60">
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          Inserire una <strong>email</strong> valida
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Inserire <strong>email</strong>
        </mat-error>
      </mat-form-field>

      <br>

      <mat-form-field class="example-full-width">
        <mat-label>Password</mat-label>
        <input type="password" matInput [formControl]="passwordFormControl" maxlength="12">
        <mat-error *ngIf="passwordFormControl.hasError('pattern') && !passwordFormControl.hasError('required')">
          Inserire una password valida: 1 lettera maiuscola, 1 numero, lunghezza minima 8 caratteri
        </mat-error>
        <mat-error *ngIf="passwordFormControl.hasError('required')">
          Inserire <strong>password</strong>
        </mat-error>
      </mat-form-field>

      <br>
      <br>

      <button mat-stroked-button color="primary" class="btn-block" type="submit"
        [disabled]="passwordFormControl.invalid || emailFormControl.invalid">Login</button>

      <div *ngIf="showResetPwd">
      <br>
      <br>
      <span >Non ricordi la password?? Clicca <a routerLink="/reset-password"> <strong>Qui</strong> </a></span>
      </div>
      <br>
      <br>
      <span>Non sei registrato? Clicca <a routerLink="/sign-up"> <strong>Qui</strong> </a></span>

    </form>

  </mat-card>

</div>
