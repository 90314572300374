<div fxFlex="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="header">
  <!-- <button mat-icon-button (click)="sidenav.toggle();"> -->



  <a routerLink="/" style="text-align: center;">
    <button mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <span>Home</span>
  </a>
  <span class="spacer"></span>

  <div style="float: right; margin-right: 20px;">
    <button mat-icon-button (click)=" logOut()">
      <mat-icon>exit_to_app</mat-icon>
      Logout
      <!-- <span (click)="logOut()" style="cursor: pointer;">LOGOUT</span> -->
    </button>
  </div>

</div>
