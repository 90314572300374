<div class="main-wrapper flex">
  <mat-card class="box flex mx-auto" [ngClass]="{'signup-message': showConfirmMessage === true}">
    <mat-card-header>
      <mat-card-title>Reset Password</mat-card-title>
    </mat-card-header>

    <form class="example-form" *ngIf="!showConfirmMessage" [formGroup]="signupFormGroup" (ngSubmit)="signup()">

      <mat-card-content>

        <mat-form-field class="example-full-width" appearance="standard">
          <mat-label>Email</mat-label>
          <input type="email" matInput formControlName="emailFormControl" placeholder="Es. nome@example.com"
            maxlength="60">
          <mat-error
            *ngIf="signupFormGroup.get('emailFormControl').hasError('email') && !signupFormGroup.get('emailFormControl').hasError('required')">
            Inserire un'indirizzo email valido
          </mat-error>
          <mat-error *ngIf="signupFormGroup.get('emailFormControl').hasError('required')">
            Inserire <strong>email</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="standard">
          <mat-label>Password</mat-label>
          <input type="password" matInput formControlName="passwordFormControl" placeholder="Password" maxlength="12">
          <mat-error
            *ngIf="signupFormGroup.get('passwordFormControl').hasError('pattern') && !signupFormGroup.get('passwordFormControl').hasError('required')">
            Inserire una password valida: 1 lettera maiuscola, 1 numero, lunghezza minima 8 caratteri
          </mat-error>
          <mat-error *ngIf="signupFormGroup.get('passwordFormControl').hasError('required')">
            Inserire <strong>password</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="standard">
          <mat-label>Conferma password</mat-label>
          <input type="password" matInput formControlName="passwordConfirmFormControl" placeholder="Conferma password"
            (input)="onPasswordConfirmInput()" maxlength="12">

          <mat-error *ngIf="signupFormGroup.get('passwordConfirmFormControl').hasError('required')">
            Inserire <strong>conferma password</strong>
          </mat-error>
          <mat-error
            *ngIf="signupFormGroup.get('passwordConfirmFormControl').hasError('confirmedDoesNotMatch') && !signupFormGroup.get('passwordConfirmFormControl').hasError('required')">
            Password <strong>non corrispondenti</strong>
          </mat-error>
        </mat-form-field>
        <br>
        <br>
      </mat-card-content>

      <button mat-stroked-button type="submit" color="accent" class="btn-block"
        [disabled]="signupFormGroup.invalid">Cambia Password</button>
      <br>
      <br>
      <span>Già registrato? Clicca <a routerLink="/sign-in"> <strong>Qui </strong></a>per il login</span>

    </form>

    <h3 *ngIf="showConfirmMessage">{{confirmMessage}}</h3>

  </mat-card>
</div>
