import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class ComponentGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot, // da informazioni sulla rotta da seguire
    state: RouterStateSnapshot // ci informa se quella rotta puo essere accessibile o meno
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checklogin(next.url[0].path);
  }

  // prima verifico che la sessione non sia scaduta
  private checklogin(path: string) {
    const state = false;
    // chiamo il service e verifico se ci sono le condizioni per entrare
    if (sessionStorage.getItem('logged') !== '1') {
      this.router.navigate(['./login']);
      return false;
    } else {
      // TODO: add catch if token is not empty
      if (
        sessionStorage.getItem('token') !== undefined &&
        sessionStorage.getItem('token') !== null &&
        sessionStorage.getItem('token') !== ''
      ) {
        const refreshTokenInfo = jwt_decode(
          sessionStorage.getItem('refreshToken')
        );
        //      const tokenInfo = jwt_decode(sessionStorage.getItem('token'));
        const Now = new Date();
        //      const expireDate = new Date(tokenInfo.exp * 1000);
        const expireDate = new Date(refreshTokenInfo['exp'] * 1000);

        if (expireDate.getTime() < Now.getTime()) {
          this.router.navigate(['/sign-in']);
          return false;
        } else {
          return this.checkComponentUrl(path);
        }
      }
      return false;
    }
  }

  // verifico che l'utente abbia accesso al percorso del componente a cui vuole accedere
  private checkComponentUrl(path: string) {
    const token = jwt_decode(sessionStorage.getItem('token'));

    let authList: string[] = token['roles'];

    // let pages = JSON.parse(JSON.stringify(PAGES));

    let authorized = false;

    if (authList.includes('ROLE_USER')) {
      authorized = true;
    }

    // pages.forEach((element: RouteInfo) => {
    //   if (element.path.includes(path)) {
    //     //    console.log('CODES: ', element.functCodes);
    //     let codes = element.functCodes;
    //     codes.forEach((code: string) => {
    //       //    console.log('code: ', code);
    //       //    console.log('functions: ',authList);
    //       if (authList.indexOf(code) > -1 || code === '1') {
    //         authorized = true;
    //       }
    //     });
    //   }
    // });
    return authorized;
  }
}
