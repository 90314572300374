import { PwdResetComponent } from './shared/pwd-reset/pwd-reset.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AuthGuard } from './auth/auth.guard';
import { MailVerificationComponent } from './shared/mail-verification/mail-verification.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

const routes: Routes = [];

export const Approutes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/starter',
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'starter',
        pathMatch: 'full',
        component: HomeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'app',
        loadChildren: () =>
          import('./components/component.module').then(
            (m) => m.ComponentsModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'sign-in',
    pathMatch: 'full',
    component: SignInComponent,
  },
  {
    path: 'sign-up',
    pathMatch: 'full',
    component: SignUpComponent,
  },
  {
    path: 'reset-password',
    pathMatch: 'full',
    component: ResetPasswordComponent,
  },
  {
    path: 'mail-check',
    pathMatch: 'full',
    component: MailVerificationComponent,
  },
  {
    path: 'pwd-reset',
    pathMatch: 'full',
    component: PwdResetComponent,
  },
  {
    path: 'privacy-policy',
    pathMatch: 'full',
    component: PrivacyPolicyComponent,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
