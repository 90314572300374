import { Component, Inject, Input, OnInit, ElementRef } from '@angular/core';
import {
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogData } from 'src/app/models/dialog-data';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isNullOrUndefined } from 'util';
// import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.css'],
})
export class ModalConfirmComponent implements OnInit {
  // @Input('messageToShow') messageToShow: string;
  messageHeader = '';
  // messageHeader = 'Conferma Operazione';
  showCancel = true;
  triggerElementRef: ElementRef = null;

  message = 'Procedere con la cancellazione della prenotazione?';

  constructor(
    public activeModal: MatDialogRef<ModalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialModalRef: MatDialogRef<any>
  ) {
    // this.dialModalRef.updatePosition({ top: '50px', left: '50px' });
    // this.dialModalRef.updatePosition({ top: '20%', left: '50%' });
  }

  ngOnInit() {
    this.showCancel = this.data.enableCancel;
    this.messageHeader = this.data.header
      ? this.data.header
      : this.messageHeader;
    this.triggerElementRef = this.data.triggerElementRef
      ? this.data.triggerElementRef
      : null;

    // console.log(window.innerWidth);
    // console.log(window.innerHeight);

    if (this.triggerElementRef !== null) {
      const matDialogConfig: MatDialogConfig = new MatDialogConfig();
      const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
      matDialogConfig.position = {
        left: `${rect.left - 150}px`,
        top: `${rect.bottom - 150}px`,
      };
      matDialogConfig.width = '300px';
      matDialogConfig.height = '200px';
      this.dialModalRef.updateSize(
        matDialogConfig.width,
        matDialogConfig.height
      );
      this.dialModalRef.updatePosition(matDialogConfig.position);
    } else {
      const matDialogConfig: MatDialogConfig = new MatDialogConfig();
      matDialogConfig.position = {
        top: `180px`,
      };

      this.dialModalRef.updatePosition(matDialogConfig.position);
    }
  }

  getMessage(): string {
    if (this.data.content !== null) {
      this.message = this.data.content;
    }
    return this.message;
  }

  closeModal() {
    this.activeModal.close(false);
  }
  continueOperation() {
    this.activeModal.close(true);
  }
}
