import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { twoDigits } from 'mat-timepicker/lib/util';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { sha512 } from 'js-sha512';
import { AppConfigService } from 'src/app/config/app.config.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  pass_regex: RegExp = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*)(+=._-]{6,20}$'
  );

  public signupFormGroup: FormGroup;

  showConfirmMessage = false;
  confirmMessage =
    'Abbiamo inviato una conferma di modifica password alla tua mail. Apri la tua mail e Clicca sul link ricevuto per confermare.';
  api_root: string;
  module_path: string;
  resource_path: string;
  full_path: any;

  constructor(
    private formBuilder: FormBuilder,
    private config: AppConfigService,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.signupFormGroup = this.formBuilder.group({
      emailFormControl: ['', [Validators.required, Validators.email]],
      passwordFormControl: [
        '',
        [Validators.required, Validators.pattern(this.pass_regex)],
      ],
      passwordConfirmFormControl: [
        '',
        [Validators.required, Validators.pattern(this.pass_regex)],
      ],
    });

    this.api_root = this.config.getConfig('api_root');
    this.module_path = this.config.getConfig('authentication_path');
    this.resource_path = 'auth/';
    this.full_path = this.api_root + this.module_path;
    console.log('SignUpInit(): ', this.full_path);
  }

  onPasswordConfirmInput() {
    // console.log('start');
    if (!this.signupFormGroup.get('passwordFormControl').hasError('required')) {
      if (
        this.signupFormGroup.get('passwordConfirmFormControl').value !==
        this.signupFormGroup.get('passwordFormControl').value
      ) {
        // console.log('setErrors');
        this.signupFormGroup.get('passwordConfirmFormControl').setErrors({
          confirmedDoesNotMatch: true,
        });
      } else {
        // console.log('else');
        this.signupFormGroup.get('passwordConfirmFormControl').setErrors(null);
      }
    }
  }

  signup() {
    // console.log(this.signupFormGroup.get('emailFormControl').value);
    // console.log(this.signupFormGroup.get('passwordFormControl').value);
    const hashedPassword = sha512
      .create()
      .update(this.signupFormGroup.get('passwordFormControl').value)
      .hex();
    this.authService
      .resetPassword(
        this.signupFormGroup.get('emailFormControl').value,
        hashedPassword
      )
      .pipe(
        catchError((error) => {
          console.log('caught mapping error and rethrowing', error);
          return throwError(error);
        })
        // finalize(() => console.log('first finalize() block executed'))
      )
      .subscribe(
        (response) => {
          this.showConfirmMessage = true;
        },
        (error) => {
          if (error.status === 429) {
            // this.isErrLogin = true;
            // this.errortype = 'errUserLogged';
            this.notificationService.showError(
              'Troppi tentativi di cambio password. Controllare la mail!'
            );
            return;
          } else {
            this.notificationService.showError(
              "Ops, c'è stato un problema! Riprova"
            );
          }
          console.log('ERRORE', error);
          console.log('STATUS OF ERROR: ', error.status);
          sessionStorage.setItem('logged', '0');
        }
      );
  }
}

export const passwordMatchValidator: ValidatorFn = (
  formGroup: FormGroup
): ValidationErrors | null => {
  if (formGroup.get('password').value === formGroup.get('password2').value)
    return null;
  else return { passwordMismatch: true };
};
