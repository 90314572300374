<h3 mat-dialog-title> {{messageHeader}}</h3>
<br>
<div mat-dialog-content>
  <h2>{{getMessage()}}</h2>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="closeModal()">No</button>
  <button mat-button (click)="continueOperation()" cdkFocusInitial>Si</button>
</div>
