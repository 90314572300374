import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
// import { OAuthService } from 'angular-oauth2-oidc';
import jwt_decode from 'jwt-decode';

@Injectable()
export class AuthGuard implements CanActivate {
  // constructor(private router: Router, private oauthService: OAuthService) {}
  constructor(private router: Router) {}

  // canActivate() {
  //   if (
  //     this.oauthService.hasValidAccessToken() &&
  //     this.oauthService.hasValidIdToken()
  //   ) {
  //     return true;
  //   } else {
  //     this.router.navigate(['/starter']);
  //     return false;
  //   }
  // }

  canActivate() {
    return this.checklogin();
  }

  private checklogin() {
    const state = false;
    // chiamo il service e verifico se ci sono le condizioni per entrare
    if (sessionStorage.getItem('logged') !== '1') {
      this.router.navigate(['./sign-in']);
      return false;
    } else {
      // TODO: add catch if token is not empty
      if (
        sessionStorage.getItem('token') !== undefined &&
        sessionStorage.getItem('token') !== null &&
        sessionStorage.getItem('token') !== ''
      ) {
        const refreshTokenInfo = jwt_decode(
          sessionStorage.getItem('refreshToken')
        );
        //      const tokenInfo = jwt_decode(sessionStorage.getItem('token'));
        const Now = new Date();
        //      const expireDate = new Date(tokenInfo.exp * 1000);
        const expireDate = new Date(refreshTokenInfo['exp'] * 1000);

        if (expireDate.getTime() < Now.getTime()) {
          this.router.navigate(['/sign-in']);
          return false;
        } else {
          return true;
        }
      }
      return false;
    }
  }
}
