import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SidenavService } from 'src/app/services/sidenav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() sidenav: MatSidenav;

  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    private authService: AuthService // public sessionTimer: SessionTimerService
  ) {
    // this.sidenav.toggle();
  }
  ngAfterViewInit(): void {
    // this.sidenav.toggle();
    // this.sidenavService.closeSideNav();
  }

  ngOnInit() {}

  logOut() {
    this.authService
      .logout()
      .pipe
      // catchError((error) => {
      //   console.log('caught mapping error and rethrowing', error);
      //   return throwError(error);
      // })
      // finalize(() => console.log('first finalize() block executed'))
      ()
      .subscribe(
        (response) => {
          sessionStorage.setItem('logged', '0');
          sessionStorage.clear();
          this.router.navigate(['/sign-in']);
        },
        (error) => {
          console.log('ERRORE', error);
          console.log('STATUS OF ERROR: ', error.status);
          sessionStorage.setItem('logged', '0');
          sessionStorage.clear();
          this.router.navigate(['/sign-in']);
        }
        // () => console.log('HTTP request completed.')
      );
  }
}
