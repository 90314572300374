<div class="main-wrapper flex">
  <mat-card class="box flex mx-auto" style="text-align: center;">
    <!-- <mat-card-header style="text-align: center;">
      <mat-card-title style="text-align: center;">Email verificata correttamente</mat-card-title>
    </mat-card-header> -->

    <ng-template [ngIf]="mailVerified">
      <img src="/assets/images/check-email-icon-25.jpg" mat-card-lg-image alt="mail-verified">

      <h1 style="text-align: center;">Password modificata correttamente</h1>

      <button type="button" class="btn btn-block btn-outline-primary" (click)="goToLogin()">
        Vai al login
      </button>
    </ng-template>

    <ng-template [ngIf]="mailVerified !== undefined && !mailVerified">
      <img src="/assets/images/error-icon.jpg" mat-card-lg-image alt="mail-verified">

      <h1 style="text-align: center;">Ops! Qualcosa è andato storto...</h1>

      <button type="button" class="btn btn-block btn-outline-primary" (click)="goToLogin()">
        Vai al login
      </button>
    </ng-template>



  </mat-card>
</div>
