import {
  HttpParams,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';

import { isNullOrUndefined } from 'util';

@Injectable({ providedIn: 'root' })
export class CommonFunction {
  // public authService : AuthService

  responseStatus: any;

  extractBodyResponse(obj: any): any {
    const appo = JSON.stringify(obj);
    let body: any;
    body = JSON.parse(appo);
    if (body !== null && body !== undefined) {
      return body.body;
    } else {
      return null;
    }
  }

  isAuthorised(functCode: string[]): boolean {
    let enabled: boolean;
    let enabledFunction: [];
    enabledFunction = JSON.parse(sessionStorage.getItem('functions'));
    enabled = false;
    enabledFunction.forEach((element) => {
      functCode.forEach((element2) => {
        if (element === element2 || element === '*') {
          enabled = true;
        }
      });
    });
    return enabled;
  }

  setHttpOptions(params: HttpParams): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        'X-Content-Type-Options': 'nosniff',
      }),
      observe: 'response' as 'body',
      params: new HttpParams(),
    };
    httpOptions.params = params;

    return httpOptions;
  }

  async delay(ms: number) {
    await new Promise((resolve) => setTimeout(() => resolve(), ms)).then(() =>
      console.log('')
    );
  }

  // pulizia parmetri dual control
  cleanDualSessionStorage() {
    sessionStorage.removeItem('username_1');
    sessionStorage.removeItem('username_2');
    sessionStorage.removeItem('username_3');
    sessionStorage.removeItem('password_1');
    sessionStorage.removeItem('password_2');
    sessionStorage.removeItem('password_3');
    sessionStorage.removeItem('Authorization1');
    sessionStorage.removeItem('Authorization2');
    sessionStorage.removeItem('Authorization3');
    sessionStorage.removeItem('userToken1');
    sessionStorage.removeItem('userToken2');
    sessionStorage.removeItem('userToken3');
  }

  // gestione errori
  errorHandler(error: any) {
    let msg: string;
    console.log('Error Handler');
    console.log(error);
    if (error instanceof HttpErrorResponse) {
      this.responseStatus = error.status;
      if (error.status === 0) {
        msg = 'Applicazione offline!';
        //    }else{
        //      msg = 'Application Offline!';
        //    }
      } else {
        //  if (this.translatel.getDefaultLang() =='it'){
        msg =
          "Si e' verificato un errore:  ${error.error.msg} (server status code ${error.status})";
        //    }else{
        //      msg = 'An error has occurred:  ${error.error.msg} (server status code ${error.status})';
        //   }
      }
      return throwError(error);
    }
  }

  extractData(res: Response) {
    const body = res.json();
    return body || {};
  }

  // funzioni di supporto per sha256
  padFunction(n: string, width: number, z = '0') {
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
  hexdumpFunction(arr: Uint8Array) {
    const hex = Array.from(arr).map((v) => this.padFunction(v.toString(16), 2));
    return hex.join('');
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        'X-Content-Type-Options': 'nosniff',
      }),
      observe: 'response' as 'body',
      params: new HttpParams(),
    };
  }

  setHeader(value: number, excludeToken?: boolean, contentType?: string) {
    let headers: HttpHeaders = new HttpHeaders();
    const token = sessionStorage.getItem('token');
    if (token && !excludeToken) {
      headers = headers.append('Authorization', token);
    }
    headers = headers.append(
      'Cache-Control',
      'no-cache, no-store, must-revalidate'
    );
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('X-Content-Type-Options', 'nosniff');
    if (value === 1 && false) {
      if (sessionStorage.getItem('MultiAuth') === '2') {
        headers = headers.append(
          'Authorization1',
          sessionStorage.getItem('userToken1')
        );
        headers = headers.append(
          'Authorization2',
          sessionStorage.getItem('userToken2')
        );
      } else if (sessionStorage.getItem('MultiAuth') === '3') {
        headers = headers.append(
          'Authorization1',
          sessionStorage.getItem('userToken1')
        );
        headers = headers.append(
          'Authorization2',
          sessionStorage.getItem('userToken2')
        );
        headers = headers.append(
          'Authorization3',
          sessionStorage.getItem('userToken3')
        );
      }
    }
    if (contentType) {
      headers = headers.append('Content-Type', contentType);
    } else {
      headers = headers.append('Content-Type', 'application/json');
    }
    return headers;
  }

  gotoTop(elem?: string) {
    const elemTmp = elem ? elem : '#moveTop';
    const scrollElem = document.querySelector(elemTmp);
    scrollElem.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  isUserAdmin() {
    if (sessionStorage.getItem('userType') === 'M') {
      return true;
    }
  }

  getEntitySession() {
    if (sessionStorage.getItem('entityCode')) {
      return sessionStorage.getItem('entityCode');
    }
  }

  isFunctionCodePresent(functionCode: string): boolean {
    if (sessionStorage.getItem('functions')) {
      let tmpFunctions: string[];
      tmpFunctions = JSON.parse(sessionStorage.getItem('functions'));
      return tmpFunctions.includes(functionCode);
    }
  }

  areFunctionCodesPresent(functionCodes: string[]): boolean {
    if (sessionStorage.getItem('functions')) {
      let tmpFunctions: string[];
      tmpFunctions = JSON.parse(sessionStorage.getItem('functions'));

      functionCodes.forEach((element: string) => {
        if (tmpFunctions.includes(element)) {
          return true;
        }
      });

      return false;
    }
  }

  convertByteSizes(size: string): string {
    const sizeBytes: number = +size;
    if (sizeBytes < 1024) {
      return size + ' bytes';
    } else {
      return size + ' kilobytes';
    }
  }

  isMulticontrol(flagAuthType: string): boolean {
    const tmpFlagAuth = +flagAuthType;
    if (tmpFlagAuth > 1 && tmpFlagAuth < 4) {
      return true;
    }
    return false;
  }

  ngbDatepickerAdapterForBackend(dateToConvert): string {
    let tmpDate = null;

    if (!isNullOrUndefined(dateToConvert)) {
      const startGenerationDate = JSON.stringify(dateToConvert).match(/\d+/g);

      tmpDate =
        startGenerationDate[2] +
        '/' +
        startGenerationDate[1] +
        '/' +
        startGenerationDate[0];
    }

    return tmpDate;
  }

  public leftPad(str: string, len: number, ch = '.'): string {
    len = len - str.length + 1;
    return len > 0 ? new Array(len).join(ch) + str : str;
  }
}
