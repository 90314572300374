import { Component, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonFunction } from 'src/app/common-function';
import { FormControl, Validators } from '@angular/forms';
import jwt_decode from 'jwt-decode';
import { sha512 } from 'js-sha512';

// import {
//   AuthConfig,
//   NullValidationHandler,
//   OAuthService,
// } from 'angular-oauth2-oidc';
// import { JwksValidationHandler } from 'angular-oauth2-oidc';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { LoginDataOut } from '../../models/authentication';
import { NotificationService } from 'src/app/services/notification.service';
import { AppConfigService } from '../../config/app.config.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  username: string;
  password: string;
  showResetPwd: boolean;
  authList: any;

  pass_regex: RegExp = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*)(+=._-]{6,20}$'
  );
  api_root: string;
  module_path: string;
  resource_path: string;
  full_path: any;

  constructor(
    private config: AppConfigService,
    private authService: AuthService,
    private commonFunction: CommonFunction,
    private router: Router,
    private notificationService: NotificationService
  ) {
    // this.configure();
  }

  // authConfig: AuthConfig = {
  //   issuer: 'https://173.249.34.38:8443/auth/realms/barber-reservation',
  //   redirectUri: window.location.origin + '/starter',
  //   clientId: 'barber-reservation-frontend',
  //   scope: 'openid profile email offline_access roles web-origins',
  //   responseType: 'code',
  //   // at_hash is not present in JWT token
  //   disableAtHashCheck: true,
  //   showDebugInformation: true,
  // };

  ngOnInit() {
    this.api_root = this.config.getConfig('api_root');
    this.module_path = this.config.getConfig('authentication_path');
    this.resource_path = 'auth/';
    this.full_path = this.api_root + this.module_path;
    console.log('LoginInit(): ', this.full_path);
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(this.pass_regex),
  ]);

  // richiama il servizio di back-end per il login e la gestione dell'auth token
  login() {
    // Base64 pwd
    // const b64Passwd = btoa(this.password);
    // console.log(this.emailFormControl.value);
    // console.log(this.passwordFormControl.value);
    const hashedPassword = sha512
      .create()
      .update(this.passwordFormControl.value)
      .hex();
    this.authService
      .login(this.emailFormControl.value, hashedPassword, this.full_path, '1')
      // .pipe(
      //   catchError((error) => {
      //     console.log('caught mapping error and rethrowing', error);
      //     return throwError(error);
      //   })
      //   // finalize(() => console.log('first finalize() block executed'))
      // )
      .subscribe(
        (response) => {
          //   console.log('responseLOgin: ', response);
          const loginResponse: LoginDataOut = this.commonFunction.extractBodyResponse(
            response
          );
          sessionStorage.setItem('username', this.username);
          const token = jwt_decode(loginResponse.jwtToken);
          // prima setto il timeout con quello del token poi se c'è il refresh lo sovrascrivo
          let timeout = new Date((+token['exp'] - 90) * 1000).toLocaleString(
            'en-ZA'
          );

          if (loginResponse.refreshToken) {
            const refreshToken = jwt_decode(loginResponse.refreshToken);

            // Set Timeout end-session check date

            //        const timeout = new Date((+token['exp'] - 90) * 1000).toLocaleString(
            //          'en-ZA'
            //        );

            timeout = new Date(
              (+refreshToken['exp'] - 90) * 1000
            ).toLocaleString('en-ZA');
          }

          sessionStorage.setItem('sessionTimeout', timeout);
          //   this.sessionTimeoutService.changeTimerStatus(false);
          //   this.sessionTimeoutService.setEndTimerDate(timeout);
          //   this.sessionTimeoutService.changeTimerStatus(true);

          sessionStorage.setItem('token', 'Bearer ' + loginResponse.jwtToken);
          sessionStorage.setItem(
            'refreshToken',
            'Bearer ' + loginResponse.refreshToken
          );

          this.authList = token['roles'];
          // this.formatFunctionArray(this.authList);
          sessionStorage.setItem('functions', JSON.stringify(this.authList));

          const userType = token['userType'];
          sessionStorage.setItem('userType', userType);
          const authType = token['authType'];
          sessionStorage.setItem('authType', authType);

          sessionStorage.setItem('expire', '1');
          sessionStorage.setItem('logged', '1');
          sessionStorage.setItem('firstName', loginResponse.firstName);
          sessionStorage.setItem('userId', loginResponse.userId);

          if (loginResponse.passwStatus === 'W') {
            // const ngbModalOptions: NgbModalOptions = {
            //   backdrop: 'static',
            //   keyboard: false,
            // };
            // const modalRef = this.modalService.open(
            //   ModalConfirmComponent,
            //   ngbModalOptions
            // );
            // modalRef.componentInstance.messageToShow =
            //   'lblUserPasswordExpiring';
            // modalRef.componentInstance.messageHeader = '';
            // modalRef.componentInstance.showCancel = false;
            // modalRef.result.then((confirmation: boolean) => {
            //   this.router.navigate(['./starter']);
            // });
          } else if (loginResponse.passwStatus === 'E') {
            // this.enableformChangePwd();
          } else {
            this.router.navigate(['./starter']);
          }
        },
        (error) => {
          console.log('ERRORE', error);
          console.log('STATUS OF ERROR: ', error.status);
          sessionStorage.setItem('logged', '0');
          if (error.status === 401) {
            // this.isErrLogin = true;
            // this.errortype = 'errUserLogged';
            this.notificationService.showError(
              'Username o password non validi!'
            );
            this.showResetPwd = true;
            return;
          } else if (error.status === 403) {
            // this.isErrLogin = true;
            // this.errortype = 'errUserLogged';
            this.notificationService.showError(
              "Utente non abilitato! Per favore attivare l'account con il link arrivato tramite email."
            );
            return;
          } else {
            this.notificationService.showError(
              "Ops, c'è stato un problema! Riprova"
            );
          }
          // if (
          //   error.error.error === 'ERR_23' ||
          //   error.error.error === 'ERR_24' ||
          //   error.error.error === 'ERR_25'
          // ) {
          //   this.isErrLogin = true;
          //   this.errortype = 'errUserNotEnabled';
          //   return;
          // }

          // if (error.error.error.indexOf('LOGIN ATTEMPTS') >= 1) {
          //   this.isErrLogin = true;

          //   this.errortype = 'errMaxLoginAttempts';

          //   return;
          // }

          // this.isErrLogin = true;
          // this.errortype = 'errUsernameOrPassword';
        }
        // () => console.log('HTTP request completed.')
      );
  }

  public logoff() {
    // this.oauthService.logOut();
  }

  // public login() {
  //   this.oauthService.initLoginFlow();
  // }

  // public logoff() {
  //   this.oauthService.logOut();
  // }

  // private configure() {
  //   this.oauthService.configure(this.authConfig);
  //   this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  //   this.oauthService.loadDiscoveryDocumentAndTryLogin();
  // }
}
